/* width */
::-webkit-scrollbar {
  /* width: 15px; */
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #d12828; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555; */
}










.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.site-navigation .site-menu .actived a span {
  color: #f8bc1a !important;
  /* Apply your desired active color here */
  border-bottom: 1px solid #f8bc1a;
}


.actr {
  color: green !important;
}

.logitx {
  color: #93ca5d;
  font-weight: 400;
  cursor: pointer;
}

.colorgreen {
  color: #93ca5d !important;
}

.logitx2 {
  color: #f4e53a;
  font-weight: 400;
  cursor: pointer;
}



.contackhover:hover {
  background-color: #000000;
  border-color: #FFFFFF;
}

.hovercircl:hover {
  cursor: pointer;
}

.avus {
  color: #398f11;
}

.avus2 {
  color: #93ca5d !important;
  cursor: pointer;
}

.boroll {
  border-radius: 12px;
}

.greencolor {
  background-color: #93ca5d !important;
  color: #FFFFFF !important;
}

.amrlef {
  margin-left: 10px !important;
}

.bg_rool {
  width: 40px;
  height: 40px;
  background-color: #FFFFFF;
  border-radius: 26px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0px !important;
  transition: all 0.5s ease !important;
}

.iconcolofoot {
  font-size: 20px;
  color: #000000 !important;
}

.bg_rool:hover {
  background-color: #f8bc1a;
  transform: scale(1.1) !important;

}

/*  */

.numyrlow {
  color: #f8bc1a;
  font-size: 54px;
  font-weight: 400;
  cursor: pointer;
}

.centera {
  display: flex;
  justify-content: center;
}

.boxleri {
  margin-left: 8% !important;
  margin-right: 8% !important;
}

.choisicon {
  fill: white;
  width: 100px !important;
  height: 100px !important;
}

.choisicon2 {
  fill: white;
  width: 80px !important;
  height: 80px !important;
  margin-bottom: 20px !important;
}

.routerbor {
  border-radius: 26px;
}

.btngreen {
  background-color: #0e7829;
  color: #ffffff;
}

.btngreen:hover {
  background-color: #000000;
  color: #ffffff;
}

.centerall {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.centerall h3 {
  text-align: center;
}

.centerall p {
  text-align: center;
  color: #000000;
}

.bgboxcolor {
  background-color: #ffffff;
  border-radius: 16px;
  min-height: 450px;
  border: 2px solid #ffffff !important;
  /* background-image: url(../../public/images/webapp.png);
  background-repeat: no-repeat;
  background-size: cover; */
}

.imagema {
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imagema img{
  width: 200px;
}

.serviuicon {
  fill: #000000;
  width: 60px;
  height: 60px;
}

.bgboxcolor:hover {
  background-color: #000000;
  box-shadow: #282c34;
}

.bgboxcolor:hover h3 {
  color: #f8bc1a;
}

.bgboxcolor:hover p {
  color: #e4e4e4;
}

.bgboxcolor:hover .serviuicon {
  fill: #f8bc1a;
}





.buildbox {
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #dfdfdf;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buildmgbox {
  max-width: 400px;
  width: 95%;
  min-height: 200px;
  height: fit-content;
  background-color: #ffffff;
  box-shadow: #ffffff;
  border-radius: 16px;
  border: 2px solid #f8bc1a;
}

.buildgif {
  width: 60px;
}

.percen {
  display: flex;
  justify-content: center;
}

.buildtx {
  font-size: 24px;
  color: #ffa600;
  text-align: center;
}

.buildtx2 {
  font-size: 20px;
  color: #000000;
  text-align: center;
}




@media only screen and (max-width: 550px) {
  .choisicon {
    width: 60px !important;
    height: 60px !important;
    margin-bottom: -5px !important;
  }

  .choisicon2 {
    width: 50px !important;
    height: 50px !important;
    margin-bottom: 0px !important;
  }

  .numyrlow {
    font-size: 34px;
    font-weight: 400;
  }
}
.margincarrer{
  margin-left: 5%;
  margin-right: 5%;
}
.all_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-box {
  cursor: pointer;
  width: 350px;
  min-height: 550px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 6px 6px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s ease;
}
.white-box:hover{
  transform: scale(1.05);
}

.box-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.box-image2 {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.box-image3 {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.box-text {
  margin: 20px 0;
  text-align: center;
  color: rgb(109, 109, 109);
}
.boxtitle{
  margin: 10px 0;
  margin-top: 30px !important;
  font-size: 24px;
  color: rgb(39, 39, 39);
  font-weight: bold;
}

.view-more-button {
  background-color: rgb(0, 143, 0);
  color: white;
  font-weight: bold;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-more-button:hover {
  background-color: rgb(0, 189, 0);
}
.tx_justy{
  text-align: justify;
}
.tx_red{
  color: red;
}