/* Careers.css */
.bg_web {
    min-height: 100vh !important;
    background-color: #eeeeee;
}

.careers-container {
    font-family: Arial, sans-serif;
    padding: 20px;
}

.header {
    text-align: center;
    margin-bottom: 20px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hettx{
    position: absolute;
    font-size: 54px;
    font-weight: bold;
    color: white;
    border: 1px solid white;
    border-radius: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 6px;
    background-color: rgb(17, 10, 36);
}

.header-image {
    width: 100%;
    /* max-height: 200px; */
}

.header-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 54px;
    font-weight: bold;
    color: white;
}

.job-box {
    background-color: #fff8f8;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.job-box:hover {
    background-color: #ffffff;
}

.job-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.job-title {
    font-size: 20px;
    font-weight: bold;
}

.job-salary {
    font-size: 16px;
    font-weight: bold;
}

.job-description {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: justify;
}

.job-description p {
    display: none;
}

.job-box.open .job-description p {
    display: block;
}

.additional-info {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.info-item {
    margin-right: 15px;
    font-size: 14px;
    font-weight: bold;
}
.timetx{
    font-size: 14px !important;
    color: rgb(77, 77, 77);
}
.apply-button {
    flex-grow: 1;
    text-align: right;
}

.apply-button button {
    background-color: green;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
}

.apply-button button:hover {
    background-color: green;
}
.maricon{
    margin-bottom: 4px;
    margin-right: 10px;
}
.rountbox{
    position: absolute;
    width: 30px;
    height: 30px;
    padding: 5px;
    margin-left: -50px;
    margin-top: -2px;
    border: 2px solid green;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.maricon2{
    color: green;
}


/* BeautifulInput.css */
/* BeautifulInput.css */
.input-container {
    position: relative;
    display: inline-block;
  }
  
  .input {
    width: 250px;
    padding: 10px;
    font-size: 16px;
    border: none; /* Hide the initial border */
    border-radius: 5px;
    background-color: #f0f0f0;
    color: #333;
    outline: none; /* Remove default focus outline */
    transition: box-shadow 0.3s ease-in-out; /* Add transition for the box shadow */
  }
  
  .input-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgb(0, 255, 0);
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease-in-out;
  }
  
  .input:focus + .input-border {
    transform: scaleX(1);
    box-shadow: 0 2px 4px rgba(0, 123, 255, 0.2); /* Show the border when focused */
  }
  .hiddinput{
    display: none !important;
  }
  .hedNav{
    position: absolute;
    width: 100%;
    height: 50px;
    top: 0 !important;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.295);
  }
  .backicon{
    color: white;
    cursor: pointer;
    font-size: 20px;
    margin-left: 15px;
  }
  .backtx{
    color: white;
    cursor: pointer;
    margin-left: 8px;
    font-size: 20px;
  }
  .bg_full{
    min-height: 60vh;
  }
  .bg_full h1{
    font-weight: bold;
    color: #616161;
  }